@pricing1: pricing1;
.@{pricing1}-wrapper {
  padding: 0px;
  background-color: #fdfdfd;
  background-image: linear-gradient(to bottom, fade(#fff, 100) 0%, fade(#c1daff, 50) 100%);
  min-height: 760px;
  .@{pricing1} {
    > p {
      text-align: center;
    }
    &-content-wrapper {
      min-height: 400px;
    }
    &-block-box {
      width: auto;
      border-radius: 0px;
      background: #eef0f3;
      text-align: center;
      color: #666;
      min-height: 400px;
      margin: auto;
      border: 1px solid transparent;
      .page-pro();
      &.active {
        border-color: rgb(188, 188, 188);
        background: #fff;
        .@{pricing1} {
          &-name {
            font-size: 18px;
            color: rgb(120, 120, 120);
          }
          &-money {
            color: #000;
          }
        }
      }
      &.alternate {
        border-color: rgb(188, 188, 188);
        background: rgb(243, 245, 254);
        .@{pricing1} {
          &-name {
            font-size: 18px;
            color: rgb(120, 120, 120);
          }
          &-money {
            color: #000;
          }
        }
      }
    }
    &-block {
      margin-bottom: 96px;
    }
    &-top-wrapper {
      width: 100%;
      padding: 24px 24px;
    }
    &-name {
      font-size: 14px;
      color: rgb(120, 120, 120);
    }
    &-money {
      font-size: 32px;
      color: rgb(120, 120, 120);
      &-frequency {
        color: rgb(120, 120, 120);
        font-size: 12px;
      }
    }
    &-content {
      font-size: 12px;
      line-height: 2;
      font-weight: 300;
      margin: 6px 24px 48px;
    }
    &-line {
      display: block;
      height: 1px;
      background: #d9d9d9;
      margin: 0 24px;
    }
    &-button-wrapper {
      margin: 24px;
      .@{pricing1}-button {
        //background-image: linear-gradient(to right bottom, #82b5ff 0%, #3c89f6 0%);
        //box-shadow: 0 8px 24px fade(#5f9bf1, 35);
        border-radius: 4px;
        border: none;
        padding: 0 42px;
        line-height: 40px;
        height: 40px;
        transition: transform 0.3s, box-shadow 0.3s;
        &:hover {
          box-shadow: 0 13px 24px fade(#5f9bf1, 45);
          background-image: linear-gradient(to right bottom, #82b5ff 0%, #3c89f6 0%);
        }
      }
    }
  }
  &.home-page-wrapper {
    .@{pricing1}-title-wrapper {
      margin-top: 0px;
      margin-bottom: 64px;
      text-align: center;
    }
  }
}

@media screen and (max-width: 767px) {
  .@{pricing1}-wrapper {
    padding-bottom: 0;
  }
}
