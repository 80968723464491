@import "~antd/lib/style/themes/default.less";

@line-color: #e9e9e9;
@font-family: "Hind", "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
@shadow-color: rgba(0, 0, 0, 0.15);

@bottom-bar-bg-color: hsl(0, 0%, 15%);
@bottom-bar-line-color: #000;
@primary-color: red;
@secondary-color: blue;
@template-bg-color: #001529;
@template-bg-color-light: #ececec;
@template-nav-bg-color: #ffffff;
@template-text-color: rgb(156, 156, 156);
@template-text-title-color: #bcbcbc;
@template-text-color-light: #fff;
@template-footer-text-color: #999;

@animate-duration: 0.45s;

/* 详细页图片或框框的样式;
*/
.page-shadow() {
  box-shadow: 0 5px 8px @shadow-color;
}

.page-pro() {
  border-radius: 6px;
  border: 1px solid @line-color;
  transform: translateY(0);
  transition: transform 0.3s @ease-out, box-shadow 0.3s @ease-out;
  &:hover {
    .page-shadow();
    transform: translateY(-5px);
  }
}
